<template>
  <div class="page-wrap">
    <div class="section-wrap">
      <div class="wrapper-left">
        <breadcrumbs :crumbs="pageData.crumbs"></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list">
            <div class="project-info">
              <div class="poster">
                <video
                  controls="1"
                  :src="state.video"
                  v-if="state.video"
                ></video>
                <el-carousel height="240px" v-else-if="state.image">
                  <el-carousel-item
                    v-for="item in state.image.split(',')"
                    :key="item"
                  >
                    <div class="wrap">
                      <img :src="item" />
                    </div>
                  </el-carousel-item>
                </el-carousel>

                <img :src="state.partnership || state.coverImage" v-else />
              </div>
              <div class="info">
                <div class="title">
                  <h3>
                    <span
                      class="title-line"
                      :title="state.title || state.investIntent"
                      >{{ state.title || state.investIntent }}</span
                    >
                    <YJSL class="jysl" :favorited="state.favoritesFlag" :targetId="state.id" :favoritesType="state.favoritesType"></YJSL>
                  </h3>
                  <div class="meta" v-if="state.intendAmount">
                    <div class="item">
                      <span class="vc"
                        ><svg-icon icon="icon_progress"></svg-icon>预筹:</span
                      >
                      <span class="light">{{ state.intendAmount }}</span>
                    </div>
                    <div class="item">
                      <span class="vc"
                        ><svg-icon icon="icon_done"></svg-icon>已筹:</span
                      >
                      <span class="light">{{
                        state.projectAmountStart || state.alreadyAmount
                      }}</span>
                    </div>

                    <div class="item" v-if="state.deadline">
                      <span class="vc"
                        ><svg-icon icon="icon_time"></svg-icon>截止:</span
                      >
                      <span class="light">{{
                        (state.deadline ?? '').split(' ')[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="block block-info-inline" v-if="state.lookFor">
                  <div class="tag">正在寻找</div>
                  <div class="cont">
                    <ul>
                      <li
                        v-for="(item, index) in state.lookFor.split(',')"
                        :key="index"
                      >
                        <span>{{ item }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="block block-info" v-if="state.funderType">
                  <div class="tag">正在寻找</div>
                  <div class="cont">
                    <ul>
                      <li v-if="state.funderType">
                        <em>投资类型: </em
                        ><span>{{
                          state.funderType == 1 ? '个人' : '集体'
                        }}</span>
                      </li>
                      <li v-if="state.investIntent">
                        <em>投资意向: </em><span>{{ state.investIntent }}</span>
                      </li>
                      <li v-if="state.investScale">
                        <em>投资规模: </em
                        ><span>{{ state.investScale }}万</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="block" v-if="state.returnMethod">
                  <div class="tag">回报方式</div>
                  <div class="cont">{{ state.returnMethod }}</div>
                </div>
                <div class="block block-author">
                  <div class="tag">发布者</div>
                  <div class="cont">
                    <div class="avator">
                      <svg-icon icon="icon_avator"></svg-icon>
                      <div>
                        <div>{{ state.userNickname }}</div>
                        <div class="em">{{ state.userIndustry }}</div>
                      </div>
                    </div>
                    <div class="cont-ext">
                      <div><el-rate v-model="rate" disabled></el-rate></div>
                      <div>
                        <span
                          >{{ state.province }}{{ state.city
                          }}{{ state.area }}</span
                        ><svg-icon icon="icon_gis"></svg-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="project-detail">
              <div class="block">
                <div class="tag">具体需求</div>
              </div>
              <div class="desc">
                <div v-if="state.label">
                  <span
                    class="tag-item"
                    v-for="(item, index) in state.label.split(',')"
                    :key="index"
                    >{{ item }}</span
                  >
                </div>
                <div>{{ state.content || state.specificRequirement }}</div>
              </div>
              <div>
                <div style="padding: 15px">
                  <div v-if="state.contact">联系人：{{ state.contact }}</div>
                  <div>
                    <PayItem @payed="handlePay" :payed="state.viewPayAmount==''" :price="state.viewPayAmount" :chargeType="state.chargeType" :targetId="state.id">
                      <span>{{ state.contactInfo }}</span>
                    </PayItem>
                  </div>
                </div>
              </div>
              <div class="contact">
                <div class="block">
                  <div class="tag">感兴趣的人</div>
                </div>
                <div class="desc">
                  <div class="avator">
                    <svg-icon icon="icon_avator"></svg-icon>
                    <span>{{
                      pageData.joinIndent.count
                        ? `(${pageData.joinIndent.count}人)`
                        : '等待参与'
                    }}</span>
                  </div>
                  <div class="join btn" @click="handleJoin">+想参加</div>
                </div>
              </div>
              <div class="comment" style="margin-top: 20px">
                <div class="block">
                  <div class="tag">评论</div>
                  <div class="desc" style="padding-top: 15px">
                    <Comment
                      :id="pageData.id"
                      :type="pageData.commentType"
                    ></Comment>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrapper-right">
            <QuickPublishProject></QuickPublishProject>
            <BlockSuggestProject class="box-suggest"></BlockSuggestProject>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  h,
  ref,
  reactive,
  defineAsyncComponent,
  computed,
  defineProps,
  getCurrentInstance,
  nextTick,
  watch,
  defineEmits
} from 'vue'
import { useRoute } from 'vue-router'
import { Options, Vue} from 'vue-class-component'
import ItemProject from '@/components/ItemProject.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestProject from '@/components/BlockSuggestProject.vue'
import YJSL from '@/components/YJSL.vue'
import Comment from '@/components/Comment.vue'
import PayItem from '@/components/PayItem.vue'
import PublishJoinProject from '@/views/publish/PublishJoinProject.vue'
import { Api } from '@/services/http'

const props = defineProps({
  type: {
    type: String,
    required: false,
  },
})
const refDialogComponent = ref()
const rate = ref(0)
const route = useRoute()
const instance = getCurrentInstance()
let pageData = reactive({
  commentType: 1,
  joinType:1,
  favoritesType:1,
  chargeType:1,
  info: {},
  crumbs: [],
  // 想参加的人
  joinIndent: { count: 0 },
})
let state = computed(() => {
  return { ...pageData, ...pageData.info, }
})

const handleJoin = () => {
  let { proxy } = instance
  let componentProxy = null
  const onMounted = (ins) => {
    componentProxy = ins.proxy
  }
  proxy
    .$msgbox({
      title: '您正在申请加入他的团队',
      message: h(PublishJoinProject, { onMounted, joinType:pageData.joinType, targetId:pageData.info.id }),
      showCancelButton: true,
      confirmButtonText: '发布',
      cancelButtonText: '取消',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = '执行中...'
          if (componentProxy) {
            const method = componentProxy.dialogSubmit || componentProxy.$.exposed.dialogSubmit
            if (method) {
              method().then((bol) => {
                if (bol) {
                  instance.confirmButtonLoading = false
                  done();
                }
              })
            } else {
              alert('error')
            }
          }
        } else {
          done()
        }
      },
    })
    .then(action => {
      proxy.$message({
        type: 'info',
        message: 'action: ' + action,
      })
    })
}

const handlePay = ()=>{
  mounted();
}
const projectId = computed(()=>{
  return route.params.id;
})
watch( projectId, (newId)=>{
  console.log('newId',newId)
  mounted();
})
const mounted = async () => {
 
  const id = route.params.id
  const path = route.path
  const typeMapper = [
    {
      regex: /coop/gi,
      value: 'partnership',
      commentType: 1,
      joinType:1,
      favoritesType:1,
      chargeType:1,
    },
    {
      regex:/project/ig,
      value:'project',
      commentType:3,
      joinType:3,
      favoritesType:3,
      chargeType:2,
    },
    {
      regex: /fund/gi,
      value: 'funder',
      commentType: 4,
      joinType:4,
      favoritesType:4,
      chargeType:3,
    },
  ]
  const find = typeMapper.find(item => item.regex.test(path))
  let type = 'project'
  let commentType = 3
  let favoritesType = 1;
  let chargeType = 1
  if (find) {
    type = find.value
    commentType = find.commentType
    favoritesType = find.favoritesType;
    chargeType = find.chargeType
  }
  pageData.id = id
  const info = await Api.get(`/client-api/${type}/get?id=` + id)

  pageData.favoritesType = favoritesType;
  pageData.commentType = commentType
  pageData.chargeType  =find.chargeType 
  pageData.info = { ...info }
  rate.value = info.userStarLevel
  pageData.crumbs = [
    {
      name: info.title || info.investIntent,
    },
  ]

  const joins = await Api.get(
    `/client-api/partnership/joinIntentCount?id=${id}`
  )
  pageData.joinIndent = { ...joins }
}
mounted()
</script>
<style lang="scss" scoped>
.section-wrap {
  padding-bottom: 30px;
  .wrapper-left {
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
  }
  .block {
    text-align: left;
    .tag {
      height: 16px;
      border-left: 5px solid #fc9507;
      line-height: 16px;
      padding-left: 10px;
      font-weight: bold;
    }
    .cont {
      padding: 10px;
      font-size: 14px;
    }
  }
  // 项目摘要
  .project-info {
    display: flex;
    position: relative;
    h3 {
      font-size: 30px;
      padding-bottom: 10px;

      max-width: 380px;
    }
    .jysl {
      position: absolute;
      right: 0px;
      top: 10px;
      width: 229px;
    }
    .meta {
      font-size: 18px;
      display: flex;
      background: #f6f6f6;
      padding: 10px;
      border-radius: 10px;
      .vc {
        display: flex;
        align-items: center;
      }
      .item {
        display: flex;
        line-height: 18px;
        font-weight: bold;
        .light {
          color: red;
        }
      }
      .item + .item {
        margin-left: 25px;
      }
      .svg-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
    .poster {
      width: 360px;
      height: 240px;
      border: 1px solid #ededed;
      img,
      video {
        width: 100%;
        height: 100%;
      }
      .wrap {
        display: flex;
        justify-items: center;
        height: 100%;
      }
    }
    .info {
      flex: 1;
      text-align: left;
      padding-left: 20px;
      .title {
        margin-bottom: 20px;
      }
      .title-line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em; /* 每行的高度 */
        max-height: 3em; /* line-height * 行数 */
      }
    }

    .block {
      margin-bottom: 10px;
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }

    .block-info {
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        vertical-align: top;

        display: inline-block;
        margin-bottom: 3px;
        margin-right: 10px;
      }
      em {
        font-style: normal;
      }
      span {
        display: inline-block;
        border: 1px solid #b6daf1;
        padding: 0 4px;
        border-radius: 4px;
        background: #dbf1ff;
        color: #666;
      }
    }
    .block-info-inline {
      span {
        display: inline-block;
        border: 1px solid #b6daf1;
        padding: 0 4px;
        border-radius: 4px;
        background: #dbf1ff;
        color: #666;
      }
      li {
        vertical-align: top;
        width: auto;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .block-author {
      .cont {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .avator {
        display: flex;
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
        .em {
          font-size: 12px;
          color: #666;
        }
      }
      .cont-ext {
        font-size: 14px;
        .svg-icon {
          margin-left: 10px;
        }
      }
    }
  }
  // 项目需求细节
  .project-detail {
    text-align: left;
    margin-top: 30px;
    .desc {
      padding: 15px;
      line-height: 24px;
      white-space: pre-wrap;
    }
    .comment,
    .contact {
    }
    .tag-item {
      display: inline-block;
      border: 1px solid #d7d7d7;
      padding: 0 4px;
      border-radius: 4px;
      background: #f1f1f1;
      color: #666;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
  .contact {
    .desc {
      display: flex;
      justify-content: space-between;
    }
    .svg-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .avator {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .btn {
      display: inline-block;
      padding: 2px 8px;
      background-color: #ff983a;
      border-radius: 4px;
      color: white;
      margin-left: 5px;
      width: 100px;
      cursor: pointer;
      flex-basis: 66px;
      text-align: center;
      height: 25px;
      &:hover {
        background-color: #ba6210;
      }
    }
  }
}
</style>
